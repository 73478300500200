<template>
  <div>
    <Loader v-if="isLoading" />
    <div v-else>
      <h3 style="margin-bottom: 25px">{{ $l.get("genoset-edit.header") }}</h3>
      <GenosetForm
        :genoset="data.genoset"
        :onSubmit="onSubmit"
        v-if="data && data.genoset"
      />
    </div>
  </div>
</template>

<script>
import GenosetEditScreenController from "@/adapters/controllers/screen-genoset-edit";
import InitGenosetEditInteractor from "@/business/genoset-edit/init-genoset-edit";
import SubmitFormInteractor from "@/business/genoset-edit/submit-form";
import GenosetForm from "@/application/components/dna/GenosetForm";
import Loader from "@/application/components/Loader";

export default {
  screen_name: "genoset-edit",
  components: { GenosetForm, Loader },
  data() {
    return {
      controller: null,
      interactors: {
        submitForm: null,
        initGenosetEdit: null
      },
      data: null,
      isLoading: false
    };
  },
  beforeMount() {
    this.controller = this.$injector.get(GenosetEditScreenController);

    //{ INTERACTORS
    this.interactors.initGenosetEdit = this.$injector.get(
      InitGenosetEditInteractor
    );
    this.interactors.submitForm = this.$injector.get(SubmitFormInteractor);
    //} INTERACTORS

    this.controller.setData = this.setData;
    this.controller.getData = this.getData;
    this.controller.setAsLoading = this.setAsLoading;
    this.controller.setAsLoaded = this.setAsLoaded;
  },
  mounted() {
    const id = this.$route.params.id;
    this.interactors.initGenosetEdit.handle(id);
  },
  methods: {
    setData(data) {
      this.data = data;
    },
    getData() {
      return this.data;
    },
    setAsLoading() {
      this.isLoading = true;
    },
    setAsLoaded() {
      this.isLoading = false;
    },
    onSubmit() {
      this.interactors.submitForm.handle(this.data.genoset);
    }
  }
};
</script>

<style lang="scss"></style>
