<template>
  <div class="genoset-form">
    <form @submit.prevent="onFormSubmit">
      <vs-card>
        <div slot="header">
          <h3>{{ $l.get("dictionary.general-data") }}</h3>
        </div>
        <vs-input
          :label="$l.get('genoset-form.name-field', 'capitalize')"
          placeholder="gs000"
          :danger="isNameInvalid"
          :danger-text="$l.get('errors.invalid-genoset', 'capitalize')"
          v-model="genoset.name"
        />
        <br />
        <div>
          <vs-textarea
            :label="$l.get('genoset-form.about-pt-br-text-field')"
            v-model="genoset.aboutPtBrText"
          />
        </div>
        <div>
          <vs-textarea
            :label="$l.get('genoset-form.about-en-us-text-field')"
            v-model="genoset.aboutEnUsText"
          />
        </div>
        <div>
          <vs-textarea
            :label="$l.get('genoset-form.about-es-es-text-field')"
            v-model="genoset.aboutEsEsText"
          />
        </div>
      </vs-card>

      <vs-card>
        <div slot="header">
          <h3>{{ $l.get("genoset-form.criteria-text-field") }}</h3>
        </div>
        <div>
          <br />
          <vs-textarea
            :label="$l.get('genoset-form.criteria-text-field')"
            v-model="genoset.criteriaText"
            v-on:keyup="onCriteriaUpdate"
          />
          <vs-alert
            color="danger"
            :active="true"
            v-for="(text, index) in criteriaErrors"
            :key="index"
            >{{ text }}</vs-alert
          >
        </div>
      </vs-card>
      <!-- <div>
          <vs-textarea
            :label="$l.get('genoset-form.criteria-field')"
            v-model="genoset.criteriaText"
          />
      </div>-->
      <input
        type="submit"
        style="position: absolute; left: -9999px; width: 1px; height: 1px;"
        tabindex="-1"
      />
    </form>
    <div style="margin-top: 25px; margin-bottom: 25px; text-align: right">
      <vs-button color="primary" type="filled" @click="onFormSubmit()">{{
        $l.get("dictionary.submit-new")
      }}</vs-button>
    </div>
  </div>
</template>

<script>
const GATES = ["and", "or", "not", "atleast"];
const RE_GT = /^([Ii]|[Rr][Ss])\d+\([A-Z]+(;[A-Z]+)?\)$/i;
const RE_GS = /^[Gg][Ss]\d+$/i;
const RE_NUMBER = /^\d+$/i;

export default {
  name: "genoset-form",
  props: ["genoset", "onSubmit"],
  data() {
    return {
      isNameInvalid: false,
      criteriaErrors: []
    };
  },
  mounted() {},
  methods: {
    onFormSubmit() {
      this.isNameInvalid = !this.genoset.name.match(RE_GS);
      this.genoset.criteriaJson = JSON.stringify(
        this.criteriaValidate(this.genoset.criteriaText)
      );
      if (!this.isNameInvalid && this.criteriaErrors.length == 0) {
        this.genoset.aboutPtBrHtml = this.genoset.aboutPtBrText
          .split("\n")
          .reduce((e, v) => `${e}<p>${v}</p>`, "");
        this.genoset.aboutEnUsHtml = this.genoset.aboutEnUsText
          .split("\n")
          .reduce((e, v) => `${e}<p>${v}</p>`, "");
        this.genoset.aboutEsEsHtml = this.genoset.aboutEsEsText
          .split("\n")
          .reduce((e, v) => `${e}<p>${v}</p>`, "");
        this.onSubmit();
      }
    },
    criteriaValidate(criteria) {
      criteria = criteria.trim();
      if (criteria.match(RE_GT) || criteria.match(RE_GS)) {
        return {
          node: criteria,
          text: criteria,
          children: []
        };
      }
      let currentChild = "";
      let children = [];
      let gate = "";
      let deep = 0;
      let isClosed = false;
      let unexpectedTokens = "";
      for (let i in criteria) {
        let token = criteria[i];
        if ([" ", "\t", "\n"].indexOf(token) > -1) {
          // continue
        } else if (isClosed) {
          unexpectedTokens += token;
        } else if (token == "(") {
          if (deep > 0) {
            currentChild += token;
          }
          deep++;
        } else if (token == ")") {
          deep--;
          if (deep > 0) {
            currentChild += token;
          } else {
            isClosed = true;
          }
        } else if (deep == 0) {
          gate += token;
        } else if (deep > 0) {
          if (deep == 1 && token == ",") {
            children.push(currentChild);
            currentChild = "";
          } else {
            currentChild += token;
          }
        }
      }
      if (currentChild) {
        children.push(currentChild);
      }
      if (deep != 0) {
        this.criteriaErrors.push(`MISSING )`);
      }
      if (unexpectedTokens) {
        this.criteriaErrors.push(`INVALID EXPRESSION: ${unexpectedTokens}`);
      }
      let isValidAtLeast = false;
      if (GATES.indexOf(gate) < 0) {
        this.criteriaErrors.push(`INVALID EXPRESSION: ${gate}`);
      } else {
        if (children.length == 0) {
          this.criteriaErrors.push(`INVALID GATE PARAMETERS: ${gate}`);
        } else if (gate == "atleast") {
          const numberStr = children[0];
          if (!numberStr.match(RE_NUMBER)) {
            this.criteriaErrors.push(`Expected number and found ${numberStr}`);
          } else {
            isValidAtLeast = true;
            const number = parseInt(numberStr);
            if (number >= children.length) {
              this.criteriaErrors.push(
                `Expected at least ${numberStr} parameters and found ${children.length -
                  1}`
              );
            }
          }
        } else if (gate == "and" && children.length < 2) {
          this.criteriaErrors.push(
            `Expected at least 2 parameters and found ${children.length}`
          );
        } else if (gate == "or" && children.length < 2) {
          this.criteriaErrors.push(
            `Expected at least 2 parameters and found ${children.length}`
          );
        }
      }
      let json_children = [];
      for (let i in children) {
        const child = children[i];
        if (isValidAtLeast && i == 0) {
          json_children.push({
            node: child,
            text: child,
            children: []
          });
        } else {
          json_children.push(this.criteriaValidate(child));
        }
      }
      return {
        text: criteria,
        node: gate,
        children: json_children
      };
    },
    onCriteriaUpdate() {
      this.criteriaErrors = [];
      let criteria_json = this.criteriaValidate(this.genoset.criteriaText);
    }
  }
};
</script>

<style lang="scss">
.genoset-form .vs-con-input-label {
  width: 100% !important;
  margin-top: 25px;
}

.genoset-form .criteria-node {
  cursor: pointer;
  padding: 0 2px;
  min-width: 60px;
  text-align: center;
  font-size: 11px;
  height: 30px;
  border: 2px solid #e8e8e3;
  border-radius: 2px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  background: #fff;
}

.genoset-form .criteria-node:active {
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.1);
  margin: 1px 0 0 1px;
  border: 2px solid #d3d3cb;
}

.genoset-form .criteria-node .node-name {
  line-height: 30px;
  color: #9b9b9b;
}

.genoset-form .criteria-node:hover .node-name {
  color: #8b8b8b;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.15);
}

// .genoset-form .vs-input {
//   margin: 15px;
// }
</style>
